<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
<!--      <el-input style="width: 200px" placeholder="请输入邮箱" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
<!--      <el-input style="width: 200px" placeholder="请输入地址" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
<!--      <el-upload action="http://www.yz-awen.cn:9090/user/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">-->
<!--        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>-->
<!--      </el-upload>-->
<!--      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>-->
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="name" label="名称" width="150"></el-table-column>
      <el-table-column prop="flag" label="唯一标识" width="120"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column label="操作"  width="380" align="center">
        <template slot-scope="scope">
          <el-button type="info" @click="selectMenu(scope.row)">分配菜单 <i class="el-icon-menu"></i></el-button>
          <el-button type="info" @click="selectToBar(scope.row)">分配toBar <i class="el-icon-mobile"></i></el-button>
          <el-button v-if="scope.row.flag !== 'SYS_ADMIN' && scope.row.flag !== 'GUEST_USER'" type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm v-if="scope.row.flag !== 'SYS_ADMIN' && scope.row.flag !== 'GUEST_USER'"
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="角色信息" :visible.sync="dialogFormVisible" width="30%" >
      <el-form label-width="80px" size="small">
        <el-form-item label="名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="唯一标识">
          <el-input v-model="form.flag" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.description" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="菜单分配" :visible.sync="menuDialogVis" width="30%">
      <el-tree
          :props="props"
          :data="menuData"
          show-checkbox
          node-key="id"
          ref="tree"
          :default-expanded-keys="expends"
          :default-checked-keys="checks">
         <span class="custom-tree-node" slot-scope="{ node, data }">
            <span><i :class="data.icon"></i> {{ data.name }}</span>
         </span>
      </el-tree>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuDialogVis = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleMenu">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog  :visible.sync="tabBarDialogVis" width="380px">
      <div style="padding-bottom: 10px;width: 160px;display: flex;flex-direction: row;justify-content: space-between;">
        <el-popover @show="popoverShow"
            placement="bottom-start"
            width="160"
            v-model="visible">
          <el-select clearable v-model="addTabBar" placeholder="选择新增导航页名称" style="width: 80%; padding: 10px" @change="selectChange" value-key="text">
              <el-option v-for="item in tabBarData" :key="item.id" :label="item.text" :value="item"></el-option>
          </el-select>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="addTabBarClick">确定</el-button>
          </div>
          <el-button slot="reference">新增</el-button>
        </el-popover>
        <el-popconfirm
            class="ml-5"
            confirm-button-text='确定'
            cancel-button-text='我再想想'
            icon="el-icon-info"
            icon-color="red"
            title="您确定删除吗？"
            @confirm="delTabBar(tabIndex)"
        >
          <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
        </el-popconfirm>
      </div>
      <el-tabs tab-position="bottom" style="" stretch @tab-click="tabsClick">
        <template v-for="item in tabBarList">
          <el-tab-pane :label="item.text"><div class="tabBarImg"><img :src="item.imgPath" class="image"></div></el-tab-pane>
        </template>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menuDialogVis = false">取 消</el-button>
        <el-button type="primary" @click="saveRoleTabBar">保 存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      tabBarData: [], // 所有的tabBar列表
      tabBarList:[
        {
          imgPath:'https://www.yz-awen.cn:9090/file/stations/河池市宜州区气象局_3.jpg',
          "pagePath": "pages/index/index",
          "text": "首页",
          "iconPath": "/assets/tabBar/index.png",
          "selectedIconPath": "/assets/tabBar/index-select.png"
        },
        {
          "pagePath": "pages/assets/index/index",
          "text": "资产管理",
          "iconPath": "/assets/tabBar/exam.png",
          "selectedIconPath": "/assets/tabBar/exam-select.png"
        },
        {
          "pagePath": "pages/equipment/index/index",
          "text": "装备管理",
          "iconPath": "/assets/tabBar/exam.png",
          "selectedIconPath": "/assets/tabBar/exam-select.png"
        },
        {
          "pagePath": "pages/environmentalmonitoring/index/index",
          "text": "环境监控",
          "iconPath": "/assets/tabBar/record.png",
          "selectedIconPath": "/assets/tabBar/record-select.png"
        },
        {
          "pagePath": "pages/my/index/index",
          "text": "我的",
          "iconPath": "/assets/tabBar/my.png",
          "selectedIconPath": "/assets/tabBar/my-select.png"
        }
      ],
      tableData: [],
      addTabBar: {},
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {},
      form1: {}, // 当前编辑角色的tabBar
      dialogFormVisible: false,
      menuDialogVis: false,
      tabBarDialogVis: false,
      visible: false,
      menuData: [],
      props: {
        label: 'name',
      },
      expends: [],
      checks: [],
      roleId: 0,
      roleFlag: '',
      ids: [],
      ids1: [],
      tabIndex: 0,
    }
  },
  created() {
    this.load()
  },
  methods: {
    popoverShow(){
      if(this.tabBarList.length === 5){
        this.visible = false
        this.$confirm('你已经分配5个tabBar导航页面，请检查。', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).then(() => { }).catch(() => { });
      }
    },

    tabsClick(tab, event){
      this.tabIndex = tab.index
    },
    addTabBarClick(){
      if(this.addTabBar.text){
        if(this.tabBarList.length<5){
          this.tabBarList.splice(this.tabIndex, 0 ,this.addTabBar)
        }
        this.visible = false
      }
    },
    load() {
      this.request.get("/role/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })

      this.request.get("/menu/ids").then(r => { // 获取菜单的ids
        this.ids = r.data
      })
      this.request.get("/tab-bar/ids").then(r => { // 获取tabBar的ids
        this.ids1 = r.data
      })

    },
    save() {
      this.request.post("/role", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    saveRoleMenu() {
      this.request.post("/role/roleMenu/" + this.roleId, this.$refs.tree.getCheckedKeys()).then(res => {
        if (res.code === '200') {
          this.$message.success("绑定成功")
          this.menuDialogVis = false

          // 操作管理员角色后需要重新登录
          if (this.roleFlag === 'SYS_ADMIN') {
            this.$store.commit("logout")
          }

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    del(id) {
      this.request.delete("/role/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    delTabBar(i){ // "我的"导航页面不要删除
      if(this.tabBarList[this.tabIndex].text === '我的'){
        this.$confirm('"我的"导航页面含有用户绑定和解绑功能，所以不要删除！', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).then(() => { }).catch(() => { });
      }else{
        this.tabBarList.splice(i, 1)
      }

    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/role/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    async selectMenu(role) {
      this.roleId = role.id
      this.roleFlag = role.flag

      // 请求菜单数据
      this.request.get("/menu").then(res => {
        this.menuData = res.data

        // 把后台返回的菜单数据处理成 id数组
        this.expends = this.menuData.map(v => v.id)
      })

      this.request.get("/role/roleMenu/" + this.roleId).then(res => {
        this.checks = res.data
        this.ids.forEach(id => {
          if (!this.checks.includes(id)) {
            // 可能会报错：Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'setChecked')
            this.$nextTick(() => {
              this.$refs.tree.setChecked(id, false)
            })
          }
        })
        this.menuDialogVis = true
      })
    },
    selectChange(item){
      console.log("wq 你选择了",item)
      this.addTabBar = item
    },
    async selectToBar(role) {
      this.roleId = role.id
      this.roleFlag = role.flag

      // 请求菜单数据
      this.request.get("/tab-bar").then(res => {
        this.tabBarData = res.data
console.log("获取导航数据，最多15个",this.tabBarData)
        // 把后台返回的tabBar数据处理成 id数组
        this.expends = this.tabBarData.map(v => v.id)
      })

      this.request.get("/role/roleTabBar/" + this.roleId).then(res => {
        var tabBarList = []
        this.checks = res.data // 获取 role_id <----> tab_bar_id
        // 如果角色没有分配tabBar,即空, 分配我的给她
        if(this.checks.length === 0){
          this.tabBarData.forEach(item=>{
            if('我的'===item.text){ tabBarList.push(item) }
          })
        }
        console.log("role_id <----> tab_bar_id:",this.checks)
        this.checks.forEach(id => { // 遍历所有tabBar的id
          this.tabBarData.forEach(item=>{
            console.log(id,'<<<>>>',item.id)
            if(id===item.id){ tabBarList.push(item) }
          })

        })
        this.tabBarList = tabBarList
        console.log("tabBarList:",tabBarList)
        this.tabBarDialogVis = true
      })
    },
    saveRoleTabBar() {
      let ids = this.tabBarList.map(function(item) {  return item.id; });
      // console.log(ids)
      this.request.post("/role/roleTabBar/" + this.roleId, ids).then(res => {
        if (res.code === '200') {
          this.$message.success("绑定成功")
          this.tabBarDialogVis = false

          // 操作管理员角色后需要重新登录
          if (this.roleFlag === 'SYS_ADMIN') {
            this.$store.commit("logout")
          }

        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>


<style scoped>
.headerBg {
  background: #eee!important;
}

.el-tabs__header {
  display: flex;
}
::v-deep .el-tabs__item {
  width: 60px;
  font-size: 12px;
  font-weight: 600;
  flex-grow: 1; /* 让所有标签宽度相等 */
  text-align: center;
  padding: 0!important;
}
::v-deep .el-tabs__item.is-active{
  background-color: #ccc!important;
}
.tabBarImg{
  width: 100%;
  height: 360px;
  background-color: #8c939d;
}
.image{
  width: 100%;
  height: 360px;
  background-color: #8c939d;
}
</style>
